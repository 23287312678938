import AuthenticationServices from "./authentication.services";

import { login, setUserData } from "../Actions";

import { IRegisterProps } from "./types";
import { AuthData } from "@cenpe/auth";
import api from "services/api";

const getEstructureDataUser = (
  token: string,
  refresh_token: string,
  idUser: number,
  type?: "teacher" | "administrator",
  userData?: any
) => {
  return {
    user: {
      ...userData,
      signed: true,
      token: token,
      refresh_token: refresh_token,
      id: idUser,
      type,
    },
  };
};

async function loadUserData({ data, dispatch }: any) {
  api.defaults!.headers!.Authorization = `Bearer ${data.access_token}`;

  const valuesLogin = getEstructureDataUser(
    data.access_token,
    data.refresh_token,
    1
  );

  sessionStorage.setItem(
    "@App:S",
    window.btoa(JSON.stringify({ ...valuesLogin }))
  );

  // Armazena o refresh token
  sessionStorage.setItem("@App:R", data.refresh_token);

  await AuthenticationServices.getIdUser().then((response: any) => {
    const valuesLogin = getEstructureDataUser(
      data.access_token,
      data.refresh_token,
      response.data.id,
      response.data.type,
      response.data
    );

    sessionStorage.setItem(
      "@App:S",
      window.btoa(JSON.stringify({ ...valuesLogin }))
    );

    sessionStorage.setItem("@App:L", JSON.stringify(response.data));
    dispatch(login(valuesLogin));
    dispatch(setUserData(response.data));
  });
}

export const auth = (props: AuthData) => {
  return (dispatch: any) => {
    loadUserData({ data: props, dispatch });
  };
};

export const register = (props: IRegisterProps) => {
  return () => {
    AuthenticationServices.register(props)
      .then(() => {
        return {
          success: 1,
        };
      })
      .catch(() => {
        return {
          success: 0,
        };
      });
  };
};

export const getIdUser = () => {
  return (dispatch: any) => {
    AuthenticationServices.getIdUser().then(({ data }) => {
      const userData: IRegisterProps = data as IRegisterProps;
      sessionStorage.setItem(
        "@App:U",
        window.btoa(JSON.stringify({ ...userData }))
      );
      dispatch(setUserData(userData));
    });
  };
};
