import { Assessment, AssessmentExam, Exam } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class AssessmentsServices {
  public static async registerAssessments(props: Assessment) {
    let obj: object = { ...props };
    delete obj["student_exams"];
    return api.post("/assessments/assessments/", JSON.stringify(obj), {
      headers: { "Content-Type": "application/json" },
    });
  }


  public static async getAllAssessmentsById(id: number) {
    return api.get("/assessments/assessments/" + id);
  }

  public static async getAllAssessments(params) {
    return api.get("/assessments/assessments/" + params);
  }

  public static async getAllAssessmentsByTeacher(idTeacher: number) {
    return api.get(`/assessments/teacheritemassignments?teacher=${idTeacher}`)
  }

  public static async getAllAssessmentsByTranscriber(idTranscriber: any) {
    let path = api.get(`/assessments/transcriberitemassignments?transcriber=${idTranscriber}`)
    return path
  }

  public static async updateAssessments(props: Assessment) {
    let obj: object = { ...props };
    delete obj["student_exams"];
    return api.patch(
      "/assessments/assessments/" + props.id + "/",
      JSON.stringify(obj),
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  public static async joinExamAssesment(idExam: number, idAssessment: number) {
    let obj: AssessmentExam = {
      id: undefined,
      exam: idExam,
      assessment: idAssessment,
    };
    return api.post("/assessments/assessmentexams/", JSON.stringify(obj), {
      headers: { "Content-Type": "application/json" },
    });
  }

  public static async deleteAssessments(props: Assessment) {
    return api.delete("/assessments/assessments/" + props.id + "/");
  }


  public static async getAllExamsById(id: number) {
    return api.get("/assessments/exams/" + id);
  }

  public static async getTaskById(id: number) {
    return api.get("/items/tasks/" + id);
  }

}
